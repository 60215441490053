import React, { Component } from 'react'
import { Link, HashRouter } from 'react-router-dom'

import MiContexto from '../MiContexto'
import UseWebService from '../UseWebService'

import { MyLoader, ButtonGoUp } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'


//reveal-effect
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import Moment from 'react-moment';

import { withTranslation, useTranslation } from 'react-i18next';


class SubscriptionInfo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            wsAllFeatures: null//contiene todas las features de catwatchful
            //La info de la suscripcion se encuentra en this.context.activeDevice.subscription
        }
    }

    componentDidMount() {
        resizeNavBar()

        this.loadDataFromWebService()
    }


    loadDataFromWebService = () => {
        const operation = "getFeaturesInf"
        UseWebService("operation=" + operation).then(resp => {
            if (resp.statusCode == 200) {
                const arrayFeaturesInf = new Array(27)
                resp.data.map(ft => { if (ft) arrayFeaturesInf[ft.featureId] = ft })
                this.setState({ wsAllFeatures: arrayFeaturesInf })
            }
        })
    }




    render() {
        const { t } = this.props;


        if (!this.state.wsAllFeatures) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t("SubscriptionInfo.title")} </h3>
                        <MyLoader text={t('SubscriptionInfo.loading')} />
                    </div>

                </div>

            )


        } else {

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('SubscriptionInfo.title')} </h3>
                        </div>
                    </div>





                    <div className='row'>

                        <div className='col-12 col-md-4'>
                            <ShowSubscriptionInfo
                                subscriptionInf={this.context.activeDevice.subscription.info}
                                statusCode={this.context.activeDevice.statusCode}
                                hoursLeft={this.context.activeDevice.hoursLeft}
                            />
                        </div>


                        <div className='col-12 col-md-8'>
                            <ShowSubscriptionFeatures
                                allFeatures={this.state.wsAllFeatures}
                                subscriptionFeaturesAvailable={this.context.activeDevice.subscription.features}
                                subscriptionName={this.context.activeDevice.subscription.info?.name}
                                color={this.context.activeDevice.subscription.info?.color}
                            />
                        </div>
                    </div>



                </div>

            )

        }


    }
}
SubscriptionInfo.contextType = MiContexto
SubscriptionInfo = withTranslation()(SubscriptionInfo);
export default SubscriptionInfo;




function ShowSubscriptionInfo(props) {
    const { t } = useTranslation();

    const { subscriptionInf, statusCode, hoursLeft } = props

    if (subscriptionInf) {


        const subscriptionImageCss = { margintop: '0px', width: '35%', height: '40%', maxWidth: '150px', maxHeight: '190px' };

        return (
            <Zoom delay={0}>

                <div className="mt-2  blueShadow" style={{ width: "370px", borderColor: "#" + subscriptionInf.color, borderStyle: "solid", borderWidth: "2px", borderRadius: "10px", backgroundColor:"#121214" }}>

                    <div className=' pb-2'>

                        <div className='text-center '>
                            <img className="card-img-top mt-2" style={subscriptionImageCss} src={"data:image/png;base64, " + subscriptionInf.logo} alt="Card image cap" />
                        </div>

                        <div className='text-center'>
                            <h2 STY className="text-center d-inline-block" style={{ color: "#" + subscriptionInf.color, marginLeft: "-15px" }}><i className="fa fa-angle-right fa-1x mr-1" />{subscriptionInf.name}</h2>
                            <div className="card-text small " style={{ color: "#" + subscriptionInf.color }}>{subscriptionInf.desc}</div>
                        </div>
                    </div>

                    <ul className="list-group list-group-flush">

                        <li className="list-group-item bg-dark" ><span className=' ' style={{ color: "#" + subscriptionInf.color }}>{t("SubscriptionInfo.usoEn")}: </span> <span style={{ fontWeight: "300" }}>{subscriptionInf.cantDevices} {t("SubscriptionInfo.devices")}</span></li>
                        <li className="list-group-item bg-dark" ><span className=' ' style={{ color: "#" + subscriptionInf.color }}>{t("SubscriptionInfo.duration")}: </span><span style={{ fontWeight: "300" }}>{subscriptionInf.daysDuration} {t("SubscriptionInfo.days")}</span></li>
                        <li className="list-group-item bg-dark text-capitalize"><span className=' ' style={{ color: "#" + subscriptionInf.color }}>{t("SubscriptionInfo.start")}: </span> <span style={{ fontWeight: "300" }}><Moment date={subscriptionInf.date} format="dddd Do, MMM YYYY, HH:mm" /> hs </span></li>

                        {statusCode == 1 ?
                            <li className="list-group-item bg-dark text-white text-capitalize" ><span className=' mr-1' style={{ color: "#" + subscriptionInf.color }}>{t("SubscriptionInfo.finish")}: </span><span style={{ fontWeight: "300" }}><Moment add={{ days: subscriptionInf.daysDuration }} format="dddd Do, MMM YYYY, HH:mm">{subscriptionInf.date}</Moment> hs </span></li>
                            :
                            <li className="list-group-item bg-dark text-danger text-capitalize" > <span className=' mr-1' style={{ color: "#" + subscriptionInf.color }}>{t("SubscriptionInfo.finish")}: </span><span style={{ fontWeight: "300" }}><Moment add={{ days: subscriptionInf.daysDuration }} format="dddd Do, MMM YYYY, HH:mm">{subscriptionInf.date}</Moment> hs </span></li>
                        }


                        <Fade delay={500}>
                            <div className="p-3 "><ShowTimeLeft hoursLeft={hoursLeft} statusCode={statusCode} subscriptionInf={subscriptionInf} /></div >
                        </Fade>
                    </ul>


                    <div className="mx-4 mb-3">
                        <HashRouter> <Link to="/buy">
                            <button className="btn btn-outline-success btn-block" ><i className="fa fa-shopping-cart mr-1"></i>{t("SubscriptionInfo.buyNew")}</button>
                        </Link>
                        </HashRouter>
                    </div>
                </div>

            </Zoom>


        )

    } else {
        //NO EXISTE SUBSCRIPCION

        return (
            <div className="row ">
                <div className="col-12 ">
                    <display className="h4 text-danger"><i className="fa fa-warning fa-x3 mr-2" />{t("SubscriptionInfo.notFound")}</display>
                    <div className="card-body">
                        <HashRouter>
                            <Link to="/buy">
                                <button className="btn btn-outline-warning btn-block" ><i className="fa fa-shopping-cart mr-2"></i> {t("SubscriptionInfo.buyNew")}</button>
                            </Link>
                        </HashRouter>
                    </div>
                </div>
            </div>
        )
    }


}


function ShowSubscriptionFeatures(props) {
    const { t } = useTranslation();

    const { allFeatures, subscriptionFeaturesAvailable, subscriptionName, color } = props


    return (

        <div className="mt-3 mb-4" style={{ width: "370px" }}>


            <div className=" mb-0 p-1 mt-4 text-left font-weight-bolder mb-0" style={{ color: "#" + color }}> {t("SubscriptionInfo.features")}:</div>


            {allFeatures.map(ft => {
                if (ft) {
                    if (subscriptionFeaturesAvailable[ft.featureId]) {
                        return (
                            <Bounce key={ft.featureId}>
                                <div className=" text-left" >
                                    <div className='p-1 pl-4' style={{ color: "#" + color, fontSize: "0.9em", fontWeight: "300" }}> <i className={"fa fa-" + ft.fa_icon + " fa-1x mr-2"} />{ft.name} <i className="fa fa-check  fa-1x ml-1 text-success" /></div>
                                </div >
                            </Bounce>
                        )
                    } else {
                        return (
                            <Bounce key={ft.featureId}>
                                <div className=" text-left" >
                                    <s className='text-secondary'><div className='p-1 pl-4 ' style={{ fontSize: "0.9em", fontWeight: "300" }}> <i className={"fa fa-" + ft.fa_icon + " fa-1x mr-2"} />{ft.name} <i className="fa fa-ban  fa-1x ml-1 text-danger" /></div></s>
                                </div >
                            </Bounce>
                        )
                    }


                }
            })}

        </div >


    )
}

function ShowTimeLeft(props) {
    const { t } = useTranslation();

    const { hoursLeft, statusCode, subscriptionInf } = props
    const isDemo = (subscriptionInf.productId == 1)

    const daysLeft = Math.round(hoursLeft / 24)
    let textColor = ""
    let progressBarColor = ""
    let rowBackground = ""

    let icon = ""
    let timeLeftMessage = ""
    let warningMessage = ""



    if (statusCode == 1) {

        if (daysLeft > 10) {
            textColor = "text-success"
            progressBarColor = "bg-success"
            icon = ""
            timeLeftMessage = t("SubscriptionInfo.daysLeft", { days: daysLeft })

        } else {

            if (!isDemo) {
                textColor = "text-danger"
                progressBarColor = "bg-warning"
                icon = "warning fa-2x"
                warningMessage = t("SubscriptionInfo.expiresSoon")
                timeLeftMessage = t("SubscriptionInfo.daysLeft", { days: daysLeft })
            } else {
                textColor = "text-white"
                icon = ""
                warningMessage = ""
                timeLeftMessage = t("SubscriptionInfo.hoursLeft", { hours: hoursLeft })
            }


            if (hoursLeft <= 10) { progressBarColor = "bg-danger"; textColor = "text-danger"; icon = ""; }

        }

    } else if (statusCode == 2 || statusCode == 3) {
        // DEMO O SUSCRIPCION EXPIRADA!!
        textColor = "text-white"
        progressBarColor = "bg-danger"
        icon = "warning fa-2x my-2"

        if (isDemo) {
            warningMessage = t("SubscriptionInfo.demoExpired")
        } else {
            warningMessage = t("SubscriptionInfo.subExpired")
        }
        rowBackground = " bg-danger rounded"
    }


    return (
        <div>

            <div className={"d-flex justify-content-center align-items-baseline row " + rowBackground}>
                <i className={"fa fa-" + icon + " " + textColor + " ml-2 mr-1 "} />
                <div className={textColor}>{timeLeftMessage}</div>
                {statusCode == 1 ? <small className='ml-1'>{warningMessage} </small> : <h5 className='ml-1'>{warningMessage} </h5>}
            </div>

            {statusCode == 1 &&
                <div className="progress bg-secondary" style={{ height: '30px' }}>
                    <div className={"progress-bar " + progressBarColor} role="progressbar" style={{ width: (hoursLeft * 100) / (subscriptionInf.daysDuration * 24) + "%" }}>
                        <span className='ml-1' style={{ color: "#000", fontSize: "1.2em" }}>{Math.round((hoursLeft * 100) / (subscriptionInf.daysDuration * 24)) + "% "}</span>
                    </div>
                </div>
            }

        </div>

    )

}
